import * as React from 'react'
import { Tab as BaseTab, TabProps as BaseTabProps } from '../../molecules/Tabs'
import { TabbedContentContext } from './TabContext'

export type TabProps = {
  /**
   * Required id for the tab. This should match up to the matching `Content`'s `tabId` attribute to display content accordingly
   */
  id: string | number
  /**
   * Used to prevent switching tab on click
   */
  preventDefaultClickBehaviour?: boolean
} & Omit<BaseTabProps<'button'>, 'id' | 'as' | 'active'>

export const Tab: React.VFC<TabProps> = (props) => {
  const { id, onClick, preventDefaultClickBehaviour, ...restProps } = props

  const { activeTab, setActiveTab } = React.useContext(TabbedContentContext)

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!preventDefaultClickBehaviour) setActiveTab(id)
    onClick?.(e)
  }

  return (
    <BaseTab
      active={activeTab === id}
      onClick={onClickHandler}
      {...restProps}
    />
  )
}
